<template>
  <app-form-view
    app="working_paper"
    model="masterworkingpaperheader"
    api-url="working-paper/master-working-paper-header/"
    :title="$t('menu.masterWorkingPaperTemplate')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.masterWorkingPaperTemplateName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="template category"
            type="select"
            rules="required"
            :label="$t('fields.templateCategory')"
            :items="[
              {
                label: $t('label.auditPlan'),
                value: 'audit_plan'
              },
              {
                label: $t('label.audit'),
                value: 'audit'
              },
              { label: $t('label.auditAndAuditPlan'), value: 'both' }
            ]"
            :view="view"
            v-model="formData.template_category"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTemplateCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_template_category"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'master-working-paper-template-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_name', 'approve_template_category']
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  methods: {}
}
</script>
